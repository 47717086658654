export const ENV_VARIABLES = Object.freeze({
  env: {
    Region: 'us-east-1',
    Stage: 'beta',
    CloudFrontProtocol: 'https',
    CloudFrontDomain: 'd1fah88628f2jt.cloudfront.net',
    CognitoUserPoolId: 'us-east-1_bDAyNaWlh',
    CognitoIdentityPoolId: 'us-east-1:7571b664-e658-4d9a-a5e9-299373614923',
    AuthenticationType: 'AMAZON_COGNITO_USER_POOLS'
  }
});

export const getAmplifyAuthConfig = () => {
  return {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_bDAyNaWlh',
      userPoolWebClientId: '7cfcto2c7cr908b1io5bijjh8u',
      identityPoolId: 'us-east-1:7571b664-e658-4d9a-a5e9-299373614923',
      awsAccountId: '535502281278',
      mandatorySignIn: true,
      authenticationFlowType: 'USER_SRP_AUTH',
      oauth: {
        domain: 'fast-input-tool-beta.auth.us-east-1.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: 'https://beta.fast-input-tool.device.finance.amazon.dev',
        redirectSignOut: 'https://beta.fast-input-tool.device.finance.amazon.dev',
        responseType: 'code'
      }
    }
  };
};
